import React from 'react'

import { useNavigate } from 'react-router-dom'


function UpcomingCard(props) {
  const navigate = useNavigate()
  return (
    <div
      key={props.index}
      className='w-full flex flex-col p-[1rem] gap-[1.5rem] overflow-auto'
      style={{
        boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)",
        background: "#fff",
      }}>
      <div className='flex flex-col justify-between gap-[1rem] h-full'>
        <div className='flex flex-col gap-[1rem] '>
          <div className='w-full flex flex-col gap-2 items-center justify-center'>
            {/* <a href='#'> */}
            {" "}
            <img
              alt='Placeholder'
              className='block h-[135px] lg:h-[200px]'
              src={props.source}
            />
            {/* </a> */}
            <div className='box  bg-black text-center p-2 w-full'>
              <h1 className='text-white text-lg font-bold'>
                {props.eventDate}
              </h1>
            </div>
          </div>

          <div className='flex flex-col gap-[0.5rem]'>

            <p className='italic font-semibold text-[#0074BE] text-[0.9rem] xl:text-[1rem] 2xl:text-[1.1rem]'>
            Limited spots available-don't miss out!
            </p>

            <div className='flex flex-row justify-between '>
              <h1 className='font-raleway text-[1rem] xl:text-[1.2rem] 2xl:text-[1.5rem] font-bold lg:font-[800] text-[#060606] leading-[99%] '>
                {props.title}
              </h1>

              <p className='font-raleway text-[1rem] xl:text-[1.2rem] 2xl:text-[1.5rem] font-bold lg:font-[800] text-[#060606] leading-[99%] '>
                ₹{props.price}
              </p>
            </div>

            <pre className='font-raleway text-[0.8rem] lg:text-[1rem] font-normal text-[#060606] leading-[1.7rem] whitespace-pre-wrap'>
              {props.shortDesc}
            </pre>
          </div>
        </div>
        <a href={props.links} target='_blank' rel='noreferrer'>
          <button
            // onClick={() => navigate(props.navigate)}
            className='bg-[#61D4FB] hover:bg-[#0074BE] hover:text-[#fff] py-[1rem] font-raleway text-[1rem] font-[700] leading-[99%] text-[#101010] w-full'
            style={{ boxShadow: " 0px 10px 30px 0px rgba(13, 13, 13, 0.15)" }}>
            Buy Tickets
          </button>
        </a>
      </div>
    </div>
  );
}

export default UpcomingCard